<template>
  <div data-app>
    <div class="row mx-0 pt-3">
      <div class="col-md-7 pl-0 row mx-0">
        <div class="col-6 py-4 px-4 border_b_silver bg-white">
          <span class="card-label font-weight-bolder Main_Blue">Skills </span>
        </div>
        <div class="col-6 p-0 pt-3 text-end border_b_silver bg-white">
          <button
            type="button"
            class="custom-add-new-record-button btn_dash_ch mr-2"
            fab
            small
            @click="createFormEnable"
          >
            <span class="v-btn__content" style="font-size: 14px !important"
              >Add new</span
            >
          </button>
        </div>
        <v-data-table
          :headers="headers"
          v-bind:items="data"
          item-key="job-function"
          class="elevation-1 px-4 rounded-0 headers_job_function_ch"
        >
          <template v-slot:item="props">
            <tr class="border__ch">
              <td
                class="Main_Blue"
                style="font-size: 14px; font-weight: 500 !important"
              >
                {{ props.item.skill_name }}
              </td>
              <td
                class="Main_Blue"
                style="font-size: 14px; font-weight: 500 !important"
              >
                <!-- {{ props.item.profession_name }} -->
                {{ props.item.job_func }}
              </td>
              <td>
                <toggle-button
                  :value="props.item.status == '' + 1 ? true : false"
                  color="#82C7EB"
                  :sync="true"
                  :labels="false"
                  @change="changeStatus(props.item)"
                />
                {{ props.item.status ? "Activated" : "Deactivated" }}
              </td>
              <td class="border-0" style="">
                <v-btn
                  class="py-2 ch_btn_silver_rounded_0"
                  fab
                  small
                  @click="editButtonClick(props.item)"
                >
                  <img
                    src="\images/job_application/Edit_Icon.svg"
                    alt=""
                    width="16"
                    height="16"
                  />
                </v-btn>
                <v-btn
                  class="py-2 ch_btn_silver_rounded_0"
                  fab
                  small
                  @click="askUserAboutDelete(props.item)"
                >
                  <img
                    src="\images/job_application/Delete.svg"
                    alt=""
                    width="16"
                    height="16"
                  />
                </v-btn>
              </td>
            </tr>
            <tr>
              <td colspan="12" style="height: 12px"></td>
            </tr>
          </template>
        </v-data-table>
      </div>
      <div class="col-md-5 pl-0 pt-0">
        <!-- create form start -->
        <div class="col-12 py-4 px-4 border_b_silver bg-white">
          <span
            v-if="createForm"
            class="card-label font-weight-bolder Main_Blue"
            >Add new Skill
          </span>
          <span
            v-if="updateForm"
            class="card-label font-weight-bolder Main_Blue"
            >Update Skill
          </span>
        </div>
        <!-- create form start -->
        <div class="col-12 bg-white p-4">
          <v-form ref="form" v-if="createForm" v-model="valid" lazy-validation>
            <v-text-field
              v-model="skill"
              :rules="nameRules"
              label="Skill"
              required
            ></v-text-field>
            <b-form-select
              v-model="professionId"
              :options="professionOptions"
              size="sm"
              class="mt-3"
            ></b-form-select>
            <div style="margin-top: 5%">
              <button
                type="button"
                @click="createSkill"
                :disabled="skill === '' || skill === null ? true : false"
                class="custom-add-new-record-button btn_dash_ch mr-2"
              >
                <span
                  class="v-btn__content px-4"
                  style="font-size: 14px !important"
                  >Save</span
                >
              </button>
            </div>
          </v-form>
          <!-- create form end -->

          <!-- update form start -->
          <v-form ref="form" v-if="updateForm" v-model="valid" lazy-validation>
            <v-text-field
              v-model="skill"
              :rules="nameRules"
              label="Skill"
              required
            ></v-text-field>
            <b-form-select
              v-model="professionId"
              :options="professionOptions"
              size="sm"
              class="mt-3"
            ></b-form-select>
            <div style="margin-top: 5%">
              <button
                type="button"
                @click="updateSkill"
                :disabled="skill === '' || skill === null ? true : false"
                class="custom-add-new-record-button btn_dash_ch mr-2"
              >
                <span
                  class="v-btn__content px-4"
                  style="font-size: 14px !important"
                  >Update</span
                >
              </button>
              <v-btn
                style="padding: 8px !important"
                class="custom-add-new-record-button btn_red__ch"
                @click="cancel"
              >
                Cancel
              </v-btn>
            </div>
          </v-form>
          <!-- update form end -->
        </div>
      </div>
    </div>
    <!-- Confirm Delete Item -->

    <v-row justify="center">
      <v-dialog v-model="dialog" persistent max-width="380px">
        <v-card>
          <v-card-title>
            <span class="headline"
              >Do you really want to DELETE this item?</span
            >
          </v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn class="custom-submit-button" text @click="dialog = false"
              >No</v-btn
            >
            <v-btn class="custom-cancel-button" text @click="deleteItem"
              ><b-spinner small type="grow" v-if="deleting"></b-spinner
              >Delete</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
import axios from "axios";
import { API_URL } from "@/core/api";
import Swal from "sweetalert2";
import SkillService from "@/MainServices/SkillService.js";

export default {
  data() {
    return {
     
      loading: true,
      dialog: false,
      deleteId: null,
      deleting: false,
      success: false,
      token: `Bearer ${localStorage.getItem("token")}`,
      valid: true,
      createForm: true,
      updateForm: false,
      skill: "",
      status:null,
      nameRules: [
        (v) => !!v || "Skill name is required",
        (v) => (v && v.length <= 20) || "Name must be less than 20 characters",
      ],
      headers: [
        { text: "Skill Name", value: "skill_name" },
        { text: "Job functions", value: "profession_name" },
        { text: "Status", value: "status" },
        { text: "Action", value: "action" },
      ],
      data: [],
      professionId: null,
      professionOptions: [{ value: null, text: "Please select Job function" }],
    };
  },
  mounted() {
    
    this.getInititalData();
    this.fetchProfession();
    this.getJobfunction(event);

  },
  methods: {

    getJobfunction(event){

      // console.log("EVENT" ,event);
      
      // axios.defaults.headers.common["Authorization"] = this.token;
      // axios
      //   .get(`${API_URL}/job-function`)
      //   .then((res) => {
      //     this.job_func = res.data;
      //     this.loading = false;
      //   })
      //   .catch(() => {
      //     this.loading = false;
      //   });

      console.log("props =>>" , this.$props)

    },


    getInititalData() {
      SkillService.getSkill()
        .then((res) => {
          this.data = res.data;
          console.log("DATA =>" , this.data)
          this.loading = false;
          // this.fetchProfession();
          
        })
        .catch(() => {
          this.loading = false;
        });
    },
    fetchProfession() {
      axios.defaults.headers.common["Authorization"] = this.token;
      axios
        // .get(`${API_URL}/profession`)
        .get(`${API_URL}/job-function`)
        .then((res) => {
          console.log('profession => ' , res.data)
          console.log('job func => ' , this.job_func)
          
          this.profession = res.data;
          res.data.forEach((element) => {
            this.professionOptions.push({
              value: element.id,
              text: element.job_function_name,
            });
          });

          // here i want to inject name
          //   this.data.map( d => {
          //   this.professionOptions.map(p => {
          //       if(d.profession_id === p.value){
          //         console.log("MATCH" , d.profession_id , p.value)
          //         d.job_func_name = p.text
          //       }
              
          //   })
          // } )

          console.log('updated' , this.data)

          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    cancel() {
      this.dialog = false;
      this.createForm = true;
      this.updateForm = false;
      this.skill = "";
      this.professionId = null;
    },
    createFormEnable() {
      this.skill = "";
      this.professionId = null;
      this.createForm = true;
      this.updateForm = false;
    },

    createSkill() {
      if (this.skill == "") {
        Swal.fire({
          title: "",
          text: "Please, provide correct data!",
          icon: "error",
          confirmButtonClass: "btn btn-secondary",
          heightAuto: false,
        });
        return;
      }
      SkillService.addSkill({
        skill_name: this.skill,
        profession_id: this.professionId,
      })
        .then((res) => {
          this.loading = false;
          this.success = true;
          this.skill = "";
          Swal.fire({
            title: "",
            text: "Skill added",
            icon: "success",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false,
          });
          this.getInititalData();
          this.cancel();
        })
        .catch((error) => {
          this.loading = false;
          Swal.fire({
            title: "",
            text: "Something went wrong!",
            icon: "error",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false,
          });
        });
    },
    updateSkill() {
      SkillService.editSkill(
        {
          skill_name: this.skill,
          profession_id: this.professionId,
          status :this.status
        },
        this.skill_id
      )
        .then((res) => {
          this.loading = false;
          this.success = true;
          this.skill = "";
          this.skill_id = "";
          Swal.fire({
            title: "",
            text: "Skill updated",
            icon: "success",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false,
          });
          this.getInititalData();
          this.cancel();
        })
        .catch((error) => {
          this.loading = false;
          Swal.fire({
            title: "",
            text: "Something went wrong!",
            icon: "error",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false,
          });
        });
    },

    changeStatus(item) {
      console.log(item)
      axios.defaults.headers.common["Authorization"] = this.token;
      axios
        .put(`${API_URL}/skill/${item.id}`, {
          skill_name: item.skill_name,
          profession_id: item.profession_id,
          status: !item.status,
        })
        .then((res) => {
          this.loading = false;
          this.success = true;
          this.skill = "";
          this.skill_id = "";
          Swal.fire({
            title: "",
            text: "Skill updated",
            icon: "success",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false,
          });
          this.getInititalData();
          this.cancel();
        })
        .catch(() => {
          this.loading = false;
          Swal.fire({
            title: "",
            text: "Something went wrong!",
            icon: "error",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false,
          });
        });
    },
    editButtonClick(item) {
      this.createForm = false;
      this.updateForm = true;
      this.skill = item.skill_name;
      this.skill_id = item.id;
      this.professionId = item.profession_id;
      this.status = item.status
    },

    deleteButtonClick(item) {
      SkillService.deleteSkill(item)
        .then((res) => {
          Swal.fire({
            title: "",
            text: "Skill deleted",
            icon: "success",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false,
          });
          this.getInititalData();
          this.cancel();
        })
        .catch(() => {
          Swal.fire({
            title: "",
            text: "Something went wrong!",
            icon: "error",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false,
          });
          this.loading = false;
        });
    },
    askUserAboutDelete(item) {
      this.deleteId = item.id;
      this.dialog = true;
    },

    deleteItem() {
      this.deleteButtonClick(this.deleteId);
    },
  },
};
</script>
