import ApiInstance from './ApiInstance.js'

export default {
  
  getSkill() {
    return ApiInstance({ requiresAuth: true }).get('/skill');
  },
  addSkill(Data) {
    return ApiInstance({ requiresAuth: true }).post('/skill', Data);
  },
  editSkill(Data, id)
  {
    return ApiInstance({ requiresAuth: true }).put(`/skill/${id}`, Data);
  },
  deleteSkill(id)
  {
    return ApiInstance({ requiresAuth: true }).delete(`/skill/${id}`);
  }
}
